import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('About');
    this.spinner.hide();

  }

}
