import { Component, OnInit } from '@angular/core';
declare var WURFL;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {
  constructor() { }
  ngOnInit() { }



  detectarDispositivoPrincipal() {
    let phone = '201212045454';
    let message = 'Hello!';

    if (WURFL.is_mobile === true && WURFL.form_factor === "Smartphone") {
      window.open('https://api.whatsapp.com/send?phone=' + phone + '&text=' + message, '_blank');
    } else {
      window.open('https://web.whatsapp.com/send?phone=' + phone + '&text=' + message, '_blank');
    }

  }

}
