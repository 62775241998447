import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BrandsService {
    api: string;

    constructor(private http: HttpClient) {
        this.api = "https://almostafa.bit68.com";
    }

    getBrands() {
        return this.http.get(`${this.api}/get_brands/`)
    }

    getBrand(id) {
        return this.http.get<any>(`${this.api}/get_brands/`).pipe(
            map(result => result.filter(item => item.id == id))
        )
    }

    getBrandProducts(id) {
        return this.http.get<any>(`${this.api}/get_brands/`).pipe(
            map(result => result.filter(item => item.id == id)),
            map(res => res[0].products)
        )
    }

    getFeaturedProducts() {
        return this.http.get<any>(`${this.api}/featured/`)
    }
}
