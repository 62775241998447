import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartCount = new BehaviorSubject(0);
  cartCountMessage = this.cartCount.asObservable();
  cart: any[];


  constructor() {
    this.getCartItems();
    this.updateCartCount();
    this.cart = [];
  }

  addToCart(item) {
    if (this.cart && !this.cart.find(x => x.id == item.id)) {
      item.quantity = 1;
      this.cart.push(item);
      this.updateCartCount();
      localStorage.setItem('cart', JSON.stringify(this.cart));
    }
  }

  removeFromCart(item) {
    this.cart.splice(this.cart.indexOf(item), 1);
    this.updateCartCount();
    this.updateCart(this.cart);
  }

  updateCartCount() {
    if (this.cart) {
      this.cartCount.next(this.cart.length);
    }
  }

  updateCart(cart) {
    if (cart) {
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }

  getCartItems() {
    this.cart = JSON.parse(localStorage.getItem('cart'));
    if (!this.cart) {
      this.cart = [];
    }

  }

  clearCart() {
    localStorage.clear();
    this.cart = [];
    this.updateCartCount();
  }

}
