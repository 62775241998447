import { OrderService } from './../order.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  order: any;
  id: number;

  constructor(private titleService: Title, private Router: Router, private ActivatedRoute: ActivatedRoute, private OrderService: OrderService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Order Details');
    this.id = +this.ActivatedRoute.snapshot.paramMap.get('id');
    this.getOrderDetails(this.id);
  }


  getOrderDetails(order_id) {
    this.OrderService.getOrderDetails(order_id).subscribe(res => {
      this.order = res;
      console.log(this.order);
      this.spinner.hide();

    })
  }
}
