import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExtrasService {
  api: string;

  constructor(private http: HttpClient) {
    this.api = "https://almostafa.bit68.com";
  }

  sendEmail(name, email, mobile, address, message) {


    return this.http.get(`${this.api}/contact-us/`, {
      params: {
        "name": name,
        "from": email,
        "phone": mobile,
        "address": address,
        "body": message
      }
    })
  }


  search(keyword) {
    return this.http.get(`${this.api}/SearchProduct/?name=${keyword}`)
  }
}
