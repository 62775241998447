import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  api: string;

  constructor(private http: HttpClient) {
    this.api = "https://almostafa.bit68.com";
  }

  placeOrder(name, email, mobile, address, company, cart, note?) {
    if (!note) {
      note = null;
    }

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    let products = [];

    cart.forEach(element => {
      products.push({
        "id": element.id,
        "amount": element.quantity,
      })
    });


    return this.http.post(`${this.api}/order/`,
      {
        "username": name,
        "email": email,
        "phonenumber": mobile,
        "address": address,
        "note": note,
        "company": company,
        // "Device": null,
        "OrderProducts": products
      }, options
    )
  }


  getOrderDetails(order_id) {
    return this.http.get(`${this.api}/order/${order_id}`)
  }

}
