import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  api: string;

  constructor(private http: HttpClient) {
    this.api = "https://almostafa.bit68.com";
  }

  getCategories() {
    return this.http.get(`${this.api}/clientscategorydetails/`)
  }

  getClients(categoryId){
    return this.http.get(`${this.api}/clientscategorydetails/${categoryId}`)
  }
}
