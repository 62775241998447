import { ClientsService } from './../clients.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  clients: any;

  constructor(private ClientsService: ClientsService, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.ClientsService.getClients(id).subscribe(res => {
      this.clients = res;
      this.spinner.hide();
    })
  }

}
