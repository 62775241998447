import { CartService } from './../../cart.service';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewChecked {
  revertFlag = false;
  carousel: HTMLElement;
  index = false;
  count: any;

  constructor(private router: Router, private CartService: CartService) { }

  ngOnInit() {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        if (JSON.parse(JSON.stringify(ev)).url === '/index') {
          this.index = true;
          this.revertFlag = false;
          console.log(this.revertFlag);
        } else {
          this.index = false;
          this.revertFlag = true;
        }
      }
    });

    this.CartService.cartCountMessage.subscribe(res => {
      this.count = res;
    })
  }



  ngAfterViewChecked() {
    if (this.index) {
      if (!this.carousel) {
        window.addEventListener('scroll', sc => {
          if (this.index) {
            this.carousel = document.querySelector('.carousel');
            if (window.scrollY > this.carousel.offsetHeight - 50) {
              this.revertFlag = true;
            } else {
              this.revertFlag = false;
            }
          }
        });
      }
    }
  }



  search(keyword) {
    this.router.navigate(['/search/'], { queryParams: { 'keyword': keyword } })
  }


}
