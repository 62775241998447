import { BrandsService } from './../brands.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CartService } from '../cart.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  products: any;
  brand: any;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private BrandsService: BrandsService,
    private CartService: CartService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Brand');
    this.getBrandProducts();
  }

  getBrandProducts() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.BrandsService.getBrand(id).subscribe(brand => this.brand = brand[0]);
    this.BrandsService.getBrandProducts(id).subscribe(result => {
      this.products = result
      this.spinner.hide();
    });

  }

  addToCart(item) {
    this.CartService.addToCart(item);
  }



}
