import { OrderService } from './../order.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  cart: any[];

  constructor(private titleService: Title, private CartService: CartService, private OrderService: OrderService, private Router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Checkout');
    this.CartService.getCartItems();
    this.cart = this.CartService.cart;
    this.spinner.hide();

  }


  onSubmit(name, email, mobile, address, company, note?) {
    this.spinner.show();
    this.OrderService.placeOrder(name, email, mobile, address, company, this.cart, note)
      .subscribe(res => {
        if ((res as any).orderid) {
          this.clearCart();
          this.Router.navigate([`/order-details/${(res as any).orderid}`]);
        }
      })
  }

  clearCart() {
    this.CartService.clearCart();
    this.cart = [];
  }

}
