import { ExtrasService } from './../extras.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  sent: boolean = false;
  error: boolean = false;

  constructor(private ExtrasService: ExtrasService, private titleService: Title, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Contact');
    this.spinner.hide();
  }

  onSubmit(name, email, mobile, address, message) {
    this.spinner.show();
    this.ExtrasService.sendEmail(name, email, mobile, address, message).subscribe(res => {
      console.log(res);
      this.sent = true;
      this.spinner.hide();
    }, err => {
      this.error = true;
      this.spinner.hide();
    })

  }

}
