import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BrandsService } from '../brands.service';
import { CartService } from '../cart.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  slider: string[];
  product: any;
  brand: any;
  related: any;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private BrandsService: BrandsService,
    private CartService: CartService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Product');
    // this.slider = ['http://www.elathemes.com/themes/mobel/assets/images/gallery-1.jpg', 'http://www.elathemes.com/themes/mobel/assets/images/gallery-2.jpg', 'http://www.elathemes.com/themes/mobel/assets/images/gallery-3.jpg']
    this.getProductDetails();
  }

  getProductDetails() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.BrandsService.getBrands().subscribe(res => {
      let brands: any = res;
      brands.forEach(brand => {
        brand.products.forEach(product => {
          if (product.id == id) {
            this.product = product;
            this.brand = brand;
            this.related = [];
            for (let i = 0; i < 4; i++) {
              this.related.push(this.brand.products[i])
            }
            console.log(this.related)
          }
        });
      });
      this.spinner.hide();

    })
  }

  addToCart(item) {
    this.CartService.addToCart(item);
  }

}
