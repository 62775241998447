import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  cart: any[];

  constructor(private titleService: Title, private CartService: CartService, private Router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    this.titleService.setTitle('Cart');
    this.CartService.getCartItems();
    this.cart = this.CartService.cart;
    this.spinner.hide();

    console.log(this.cart)
  }

  ngOnDestroy(): void {
    this.spinner.show();
    this.updateCart();
  }

  checkout() {
    this.Router.navigate(['/checkout']);
  }

  removeFromCart(item) {
    this.CartService.removeFromCart(item);
  }

  clearCart() {
    this.spinner.show();
    this.CartService.clearCart();
    this.cart = [];
    this.Router.navigate(['/index']);
  }

  updateCart() {
    this.CartService.updateCart(this.cart);
  }
}
