import { ClientsService } from './../clients.service';
import { CartService } from './../cart.service';
import { BrandsService } from './../brands.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  slider: string[];
  carousel: any;
  brands: Object;
  featured: any;
  clients: any[];

  constructor(private ClientsService: ClientsService, private titleService: Title, private BrandsService: BrandsService, private CartService: CartService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.titleService.setTitle('ALMOSTAFA');
    this.slider = ['assets/img/cars.jpg', 'assets/img/products.jpg', 'assets/img/clients.jpg']
    // this.clients = [
    //   { 'name': 'hotels', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/hotel.jpeg' },
    //   { 'name': 'retail', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/retail.jpeg' },
    //   { 'name': 'restaurant', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/restaurant.jpeg' },
    //   { 'name': 'companies', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/company.jpg' },
    //   { 'name': 'hospitals', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/hospital.jpeg' },
    //   { 'name': 'sport clubs', 'image': 'https://almostafa-bit68.s3.amazonaws.com/assets/club.jpeg' },
    // ]
    this.getClients();
    this.getBrands();
    this.getFeatured();
  }
  getClients() {
    this.spinner.show();
    this.ClientsService.getCategories().subscribe((res: any) => {
      this.clients = res;
      this.spinner.hide();
    })
  }

  getBrands() {
    this.spinner.show();
    this.BrandsService.getBrands().subscribe(res => {
      this.brands = res;
      this.spinner.hide();
    })
  }

  getFeatured() {
    this.spinner.show();
    this.BrandsService.getFeaturedProducts().subscribe(res => {
      this.featured = res;
      this.spinner.hide();
    })
  }

  addToCart(item) {
    this.CartService.addToCart(item);
  }
}
