import { ExtrasService } from './../extras.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  products: any;

  constructor(private ExtrasService: ExtrasService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.spinner.show();
    this.route.queryParams.subscribe(res => {
      console.log(res);
      
      // this.location.replaceState("keyword");
      this.ExtrasService.search(res.keyword).subscribe(res => {
        this.products = res;
        this.spinner.hide();
      }, err => {
        this.products = undefined;
        this.spinner.hide();
      })
    });

  }

}
